import './vendor';

import './login';
import './project/edit';
import './validate';
import './zebra.scss';

$(() => {
  $('.project-selector a').on('click', (e) => {
    const project_id = $(e.target).data('project-id') || $('#project').val();

    $.ajax({
      type: 'POST',
      url: WEB_ROOT + '/project/select/' + project_id + '.json',
    }).done((msg) => {
      if (!msg.result) {
        alert(msg.error.message);
        return;
      }
      // 成功したらダッシュボードへ
      location.replace(WEB_ROOT + '/dashboard');
    });
  });

  $('.btn-space-update').on('click', function () {
    $.ajax({
      type: 'POST',
      url: WEB_ROOT + '/space/update.json',
      data: $('form#space').serialize(),
    }).done(function (msg) {
      if (msg.result) {
        // 成功したらカスタマー詳細へ
        alert('スペース設定を保存しました');
        location.reload();
      } else {
        // バリデーションエラーを一旦消す
        $('.validation-error').html('');

        // 失敗したら各エラー表示
        $.each(msg.data, function (key: string) {
          const str = Array.isArray(this) ? this.join('<br />') : this;
          $('div[id=error-' + key + ']').html(str);
        });
      }
    });
  });

  $('.btn-project-create').on('click', () => {
    $.ajax({
      type: 'POST',
      url: WEB_ROOT + '/project/regist.json',
      data: $('form#project').serialize(),
    }).done((msg) => {
      if (msg.result) {
        // 成功したらリロード
        alert('プロジェクトデータの更新に成功しました');
        location.reload();
      } else {
        // バリデーションエラーを一旦消す
        $('.validation-error').html('');

        // 失敗したら各エラー表示
        $.each(msg.data, function (key: string) {
          const str = Array.isArray(this) ? this.join('<br />') : this;
          $('#modal-project-create div[id=error-project-' + key + ']').html(
            str,
          );
        });
      }
    });
  });

  $('.btn-account-create').on('click', (e) => {
    $(e.currentTarget).attr('disabled', 'disabled');
    $.ajax({
      type: 'POST',
      url: WEB_ROOT + '/account/regist.json',
      data: $('form#account').serialize(),
    })
      .done((msg) => {
        if (msg.result) {
          // 成功したらリロード
          alert('アカウントを作成しました');
          location.reload();
        } else {
          // バリデーションエラーを一旦消す
          $('.validation-error').html('');

          // 失敗したら各エラー表示
          $.each(msg.data, function (key: string) {
            const str = Array.isArray(this) ? this.join('<br />') : this;
            $('#modal-account-add div[id=error-account-' + key + ']').html(str);
          });
        }
      })
      .always(() => {
        $(e.currentTarget).attr('disabled', null);
      });
  });
});

$(() => {
  $('.btn-project-update').on('click', (e) => {
    const project_id = $(e.target).data('project-id');
    const url =
      typeof project_id === 'undefined'
        ? `${WEB_ROOT}/project/regist.json`
        : `${WEB_ROOT}/project/update/${project_id}.json`;
    $.ajax({
      type: 'POST',
      url: url,
      data: $('#form-project').serialize(),
    }).done((msg) => {
      if (msg.result) {
        // 成功したらリロード
        alert('プロジェクトデータの更新に成功しました');

        if (msg.data && msg.data.project_id) {
          location.href = `${WEB_ROOT}/project/edit/${msg.data.project_id}`;
        } else {
          location.reload();
        }
      } else {
        // バリデーションエラーを一旦消す
        $('.validation-error').html('');

        // 失敗したら各エラー表示
        $.each(msg.data, function (i) {
          var str = this;
          if (this instanceof Array) {
            str = this.join('<br />');
          }
          $(`div[id=error-${i}]`).html(str);
        });
        alert(msg.error.message);
      }
    });
  });

  $('.btn-project-delete').on('click', (e) => {
    console.log($(e.target).data('project-id'));
    if (!window.confirm('このプロジェクトを削除します。\nよろしいですか？')) {
      return;
    }
    const project_id = $(e.target).data('project-id');
    $.ajax({
      type: 'POST',
      url: `${WEB_ROOT}/project/delete/${project_id}.json`,
    }).done((msg) => {
      if (msg.result) {
        // 成功したらスペース設定に戻る
        location.href = WEB_ROOT + '/space/edit';
      } else {
        // 失敗したらエラー表示
        alert(msg.error.message);
      }
    });
  });

  // トークン作成
  $('#new-token').on('click', () => {
    // アクセストークンを取得する
    $.ajax({
      type: 'POST',
      url: `${WEB_ROOT}/project/token.json`,
    })
      .done((res) => {
        if (!res.result) {
          // todo: エラー処理
          return;
        }

        $('#access-token').text(res.data.accessToken);
        $('#modal-token-new').modal('show');
      })
      .fail(() => {
        // todo: エラー処理
        console.error("can't create token.");
      });
  });

  $('#modal-token-new').on('hidden.bs.modal', () => {
    // リロード
    location.reload();
  });

  var tokenId = 0;
  $('#modal-token-delete').on('show.bs.modal', (e) => {
    tokenId = e.relatedTarget.dataset.tokenId;
    console.log(tokenId);
  });

  $('#delete-token').on('click', () => {
    if (tokenId === 0) {
      // something wrong
      console.error('missing token id.');
      return;
    }

    // アクセストークンを削除する
    $.ajax({
      type: 'POST',
      url: `${WEB_ROOT}/project/token/delete/${tokenId}.json`,
    })
      .done(() => {
        $('#modal-token-delete').modal('hide');
        tokenId = 0;
        location.reload();
      })
      .fail(() => {
        console.error("can't delete token.");
      });
  });

  $('#modal-category-update').on('show.bs.modal', function (event) {
    const button = $(event.relatedTarget); // Button that triggered the modal
    const id = button.data('id'); // Extract info from data-* attributes
    const name = button.data('name'); // Extract info from data-* attributes
    // // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
    // // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
    const modal = $(this);
    modal.find('.modal-body input').val(name);
    modal.find('.modal-footer .btn-category-update').data('id', id);
  });

  $('.btn-category-update, .btn-category-create').on('click', function (event) {
    const button = $(event.target);
    const modal = button.closest('.modal');
    const id = button.data('id') ?? 'store';
    $.ajax({
      type: 'POST',
      url: `${WEB_ROOT}/category/${id}.json`,
      data: modal.find('form').serialize(),
    }).done((msg) => {
      if (msg.result) {
        // 成功したらリロード
        alert('カテゴリを登録しました');
        modal.modal('hide');
        location.reload();
      } else {
        // バリデーションエラーを一旦消す
        $('.validation-error', modal).html('');

        // 失敗したら各エラー表示
        console.log(JSON.parse(msg.error.message));
      }
    });
  });
});

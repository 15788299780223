declare var WEB_ROOT: string;

$(() => {
  // ページ読み込み時に自動でコードをメールアドレスを表示
  const login_code = $.cookie('login_code');
  if (typeof login_code !== 'undefined') {
    $('input[name="login_code"]').val(login_code);
  }
  const login_email = $.cookie('login_email');
  if (typeof login_email !== 'undefined') {
    $('input[name="login_email"]').val(login_email);
  }

  // 自動でフォーカスも移動してあげる
  if (typeof login_code !== 'undefined' && typeof login_email !== 'undefined') {
    $('input[name="login_password"]').focus();
  } else {
    $('input[name="login_code"]').focus();
  }

  $('#login input').keydown((e) => {
    if (e.keyCode === 13) {
      $('#login-btn').click();
    }
  });

  $('#login-btn').on('click', () => {
    $.ajax({
      type: 'POST',
      url: WEB_ROOT + '/login.json',
      data: $('#login').serialize(),
    }).done((response) => {
      if (!response.result) {
        const messages = JSON.parse(response.error.message);
        $('#error').empty();
        for (let key in messages) {
          $('#error').append(`${messages[key].join('<br>')}<br>`);
        }
        $('#error').closest('.alert').show();
        return;
      }

      $('#error').closest('.alert').hide();

      // ログイン成功したらコードとメールアドレスをcookieに保存する
      const options = { expires: 7 };
      $.cookie('login_code', $('input[name="login_code"]').val(), options);
      $.cookie('login_email', $('input[name="login_email"]').val(), options);

      // ログイン成功したらダッシュボードへ
      location.replace(WEB_ROOT + '/dashboard');
    });
  });

  $('.btn-logout').on('click', () => {
    $.ajax({
      type: 'POST',
      url: `${WEB_ROOT}/logout.json`,
    }).done((msg) => {
      if (!msg.result) {
        console.log(msg);
        return;
      }
      location.href = `${WEB_ROOT}/`;
    });
  });
});
